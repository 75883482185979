<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.pagos.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.pagos.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.pagos.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <!--<v-card-title>
            <v-row>
              <v-col>
                <v-select
                  v-model="filters.selected.items"
                  :items="filters.data.items"
                  item-text="nombre"
                  item-value="id"
                  :label="$t('app.sucursales.name')"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  outlined
                  rounded
                  dense
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="filters.selected.query"
                  :label="$t('app.buttons.search')"
                  rounded
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  clearable
                />
              </v-col>
            </v-row>
          </v-card-title>-->

          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="10"
            :search="queryClean"
            :custom-filter="customFilter"
          >
            <template v-slot:item.costo_final="{ item }">
              {{ item.detalle.divisa.symbol }}
              {{ item.detalle.costo_final }}
              {{ item.detalle.divisa.code }}
            </template>
            <template v-slot:item.descuento="{ item }">
              {{ item.detalle.divisa.symbol }}
              {{ item.detalle.descuento }}
              {{ item.detalle.divisa.code }}
            </template>
            <template v-slot:item.costo_neto="{ item }">
              {{ item.detalle.divisa.symbol }}
              {{ item.detalle.costo_neto }}
              {{ item.detalle.divisa.code }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <pago-add />
    <pago-detail />
  </v-container>
</template>

<script>
import latinize from 'latinize'
import customFilter from "../../../../plugins/customFilter";

  export default {
    name: 'Pago',
    mixins: [customFilter],
    components: {
      PagoAdd: () => import('./PagoAdd'),
      PagoDetail: () => import('./PagoDetail'),
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.conceptos_pago.single'),
            value: 'detalle.concepto.descripcion',
          },
          {
            text: this.$t('app.headers.final_price'),
            value: 'costo_final',
          },
          {
            text: this.$t('app.headers.discount'),
            value: 'descuento',
          },
          {
            text: this.$t('app.headers.net_price'),
            value: 'costo_neto',
          },
          {
            text: this.$t('app.headers.notes'),
            value: 'detalle.notas',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        filters: {
          data: {
            items: [],
          },
          selected: {
            items: [],
            query: '',
          },
        },
      }
    },
    computed: {
      filteredItems () {
        if (this.filters.selected.items.length > 0) {
          return this.items.filter(item => this.filters.selected.items.includes(item.sucursal_id))
        } else {
          return this.items
        }
      },
      queryClean () {
        return latinize(this.filters.selected.query)
      }
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      async init () {
        this.toggleLoader()

        this.filters.data.items = await this.getBranches()
        this.items = await this.getPagos()

        this.toggleLoader()
      },
      async getPagos () {
        let items = []

        await this.$http.get(route('v1/payment'))
          .then(response => {
            if (response.body.code === 200) {
              items = response.body.data
            } else {
              this.processError(response)
            }
          }, error => this.processError(error))

        return items
      },
      openItemAdd () {
        EventBus.$emit('pago-add')
      },
      openItemDetail (item) {
        EventBus.$emit('pago-detail', item)
      },
    },
  }
</script>

<style scoped>

</style>
